
import { defineComponent, onBeforeMount, ref } from 'vue'
import { AddressConfig } from '@/types'
import { getUserAddresses } from '@/api/user'
import { ERR_OK } from '@/api/config'
export default defineComponent({
  name: 'UserAddress',
  setup () {
    const addressList = ref<AddressConfig[]>([])
    onBeforeMount(async () => {
      const { code, data } = await getUserAddresses<AddressConfig[]>()
      if (code === ERR_OK) {
        addressList.value = data
      }
    })
    return { addressList }
  }
})
